// axios
import axios from 'axios'

// 引入路由(对异常状态码做出跳转页面处理)
// 例如 [登录已过期]: 拦截到后跳转到登录页面
import store from '../vuex-store.js'
import localStorage from '@/utils/localStorage'

// 引入 elementui 提示框组件(根据你的UI框架来)
// 例如 [登录已过期]: 需要给用户弹出提示框来告知用户
import {
	Message,
	MessageBox
} from 'element-ui'

// 获取token/uid(一般都会在请求携带token/uid等信息)
// [如果你的服务端接口没有做这件事那不需要]


// 开发环境切换(基本分为开发环境与线上环境)
// 用一个标识很轻松就能切换服务器接口地址
if (window.location.hostname != 'localhost') {
	var debug = true //标识
}
var baseURL //baseURL
if (!debug) {
	baseURL = 'https://boot.aispotlight.cn' //线上接口
} else {
	baseURL = 'https://boot.aispotlight.cn' //线下接口
}
var codeLogin = 0
var coqd = 0
var upload = baseURL + '/api/system/uploadavatar'
// 配置请求基本信息(详见文档 | 这里只做了简单的配置)
// baseurl / 超时时间(5秒) / 
axios.defaults.baseURL = baseURL
axios.defaults.timeout = 70000
axios.defaults.withCredentials = true;
// 添加请求拦截器
axios.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	// if (token == null && config.url != '/index/login/doLogin') {
	// 	coqd=1
	// 	location.reload();
	// 	return false
	// } else {
	return config;
	// }
}, function(error) {
	// 对请求错误做些什么
	// return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function(response) {
	// 对响应数据做点什么
	return response;
}, function(error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});






/**
 * request - 请求封装
 * @param url
 * @param data
 * @returns Promise
 */
export function request(config) {
	if (codeLogin == 1) return
	axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
	axios.defaults.headers = {
		...axios.defaults.headers,
		...config.headers
	}
	if (config.method == 'GET') {
		// axios.defaults.headers=['Content-Type'] = 'application/x-www-form-urlencoded';
	}
	return new Promise((resolve, reject) => {
		axios({
				method: config.method ? config.method : 'post',
				url: config.url,
				data: config.data,
				params: config.params,
			})
			// 请求成功回调
			.then(response => {
					// console.log(response)
					if (response.data.code !== 200) {
						if (response.config.url = '/login' && response.data.msg == "用户不存在") {
							resolve(response.data);
							return false
						}
						Message({
							message: response.data.msg,
							customClass: "error-message",
							type: 'error'
						})
						// 登录失效
						if (response.data.code == 401) {
							store.state.loginshow = true
							return false
						}
						return false
					}
					// ok(正常返回)
					resolve(response.data);
				},
				// 请求失败回调
				err => {
					// 请求失败后(做一个弹出框提示用户)
					// 当然也可以做其他操作
					if (coqd == 1) return
					Message({
						showClose: true,
						customClass: "error-message",
						duration: '10000',
						message: '请求失败(可能是网络问题)',
						type: 'error'
					})
					reject(err)
				})
	})
}

export function http() {
	var config = {
		upload: upload,
	}
	return config
}
// 下载文件
export function Download(url, name) {
	const x = new XMLHttpRequest();
	x.open('GET', url, true);
	x.responseType = 'blob';
	x.onload = function(e) {
		const url2 = window.URL.createObjectURL(x.response);
		const elink = document.createElement('a');
		elink.href = url2;
		elink.target = '_self'; // 当前也 target打开新页面
		// elink.setAttribute('download', fileName);
		elink.download = name;
		elink.style.display = 'none';
		document.body.appendChild(elink);
		setTimeout(() => {
			elink.click();
			document.body.removeChild(elink);
		}, 66);
	};
	x.send();
}

export {
	baseURL
};