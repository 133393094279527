<template>
	<div class="main1">
		<div class="links">
			<router-link :to="{name:'index'}"><i class="iconfont icon-home-alt"></i></router-link>/<div>
				{{$t('menu.request')}}
			</div>/
			<div>{{$t('request.xqtj')}}</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="deck">
					<span> {{$t('request.xqtj')}}</span>
				</div>
				<div class="label">
					{{$t('request.wtlx')}}...
				</div>
				<div class="option" @click="demandType=1">
					<div class="check">
						<i v-if="demandType==1" class="el-icon-success"></i>
					</div>
					<div class="text">
						{{$t('request.xzgj')}}
					</div>
				</div>
				<div class="option" @click="demandType=2">
					<div class="check">
						<i v-if="demandType==2" class="el-icon-success"></i>
					</div>
					<div class="text">
						{{$t('request.zdtj')}}
					</div>
				</div>
				<div class="option" @click="demandType=3">
					<div class="check">
						<i v-if="demandType==3" class="el-icon-success"></i>
					</div>
					<div class="text">
						{{$t('request.qt')}}
					</div>
				</div>
				<div class="label">
					{{$t('request.title')}}
				</div>
				<input type="text" class="titleInput" v-model="demandTitle" :placeholder="$t('request.xqlx')" />
				<div class="label">
					{{$t('request.xxsm')}}
				</div>
				<textarea v-model="demandDescription" :placeholder="$t('request.xqms')"></textarea>
				<div class="submit" @click="handsubmit">
					{{$t('request.submit')}}
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {
				demandType: 1,
				demandTitle: '',
				demandDescription: '',
			}
		},
		mounted() {},
		methods: {
			handsubmit() {
				this.$request({
					url: '/demand',
					data: {
						demandTitle: this.demandTitle,
						demandDescription: this.demandDescription,
						demandType: this.demandType,

					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.$router.push({
							name: 'requests',
						})
					}
				})
			},
		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;



				.deck {
					font-size: 16px;
					color: #C0C2CC;
					display: flex;
					flex-direction: column;

					span {
						font-size: 36px;
						font-weight: 500;
						color: #E9E9EF;
					}
				}

				.label {
					font-size: 20px;
					color: #E9E9EF;
					font-weight: 500;
					margin-top: 30px;
					margin-bottom: 8px;
				}

				.option {
					width: 100%;
					height: 62px;
					border: 1px solid #283150;
					border-radius: 6px;
					display: flex;
					align-items: center;
					padding: 0 8px;
					margin-top: 8px;
					cursor: pointer;

					.check {
						width: 14px;
						height: 14px;
						border: 1px solid #283150;
						border-radius: 50%;
						margin: 0 8px;
						display: flex;
						align-items: center;
						justify-content: center;

						i {
							font-size: 14px;
							color: #fff;
						}
					}

					.text {
						font-size: 16px;
						font-weight: 500;
						color: #E9E9EF;
					}
				}

				.titleInput {
					width: 100%;
					height: 50px;
					border-radius: 6px;
					background: #111523;
					margin-top: 8px;
					font-size: 16px;
					color: #fff;
					padding: 0 16px;
				}

				textarea {
					width: 100%;
					height: 236px;
					border-radius: 6px;
					background: #111523;
					margin-top: 8px;
					font-size: 16px;
					color: #fff;
					padding: 14px 16px;
					line-height: 26px;
				}

				.submit {
					width: 230px;
					height: 44px;
					background: #333647;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 32px;
					cursor: pointer;
					font-size: 16px;
					color: #C0C2CC;
					font-weight: 500;
				}

			}

			.featured {
				width: 296px;

				.title {
					font-size: 26px;
					font-weight: 600;
					color: #C0C2CC;
					height: 36px;
					margin-bottom: 20px;
				}

			}
		}




	}
</style>