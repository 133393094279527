import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n); // 全局挂载

const messages = {
	zh: require("./lang/zh"), // 中文语言包
	en: require("./lang/en") // 英文语言包
}

export const i18n = new VueI18n({
	// locale: localStorage.getItem("locale") || "zh", // 从localStorage中获取 默认英文
	locale: getLanguage(messages),
	messages
});


//获取当前语言环境，通过后台返回的语言或者浏览器语言环境
export function getLanguage() {

	const language = (navigator.language || navigator.browserLanguage).toLowerCase()
	console.log(language)
	const locales = Object.keys(messages) //获取前端设置的所有语言
	//遍历所有语言值组成的数组，匹配前端设置的语言能匹配到就返回改语言值
	for (const locale of locales) {
		if (language.indexOf(locale) > -1) {
			return locale
		}
	}
	//如果都没匹配到就直接返回英文
	return 'en'
}
export default i18n;