<template>
	<div class="main1">
		<div class="links">
			<router-link :to="{name:'index'}"><i class="iconfont icon-home-alt"></i></router-link>/<div>
				{{$t('menu.request')}}
			</div>/
			<div>{{info.demandTitle}}</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="userinfo">
					<div class="user">
						<img src="../../../static/images/icon/user.png" alt="" />
						<div class="info">
							<div class="name">
								{{info.nickname}}
							</div>
							<div class="time">
								<i class="iconfont icon-time"></i>{{info.demandTime}}
							</div>
						</div>
					</div>
					<div class="vote">
						<div class="number">
							{{info.demandPoll}}
						</div>
						vote
						<span> {{info.demandReplySum}} answers</span>
						<div class="post" @click="handvote()">
							VOTE
						</div>
					</div>
				</div>
				<div class="deck">
					<span> {{info.demandTitle}}</span> {{info.demandDescription}}
				</div>
				<div class="leave">
					<div class="leavelist" v-for="(item,index) in info.demandReplyList" :key="index">
						<img src="../../../static/images/icon/user.png" alt="" />
						<div class="info">
							<div class="name">
								{{item.nickName}}
							</div>
							<div class="time">
								{{item.replyTime}}
							</div>
							<div class="msg">
								<span>
									{{item.demandReply}}
								</span>
							</div>
							<div class="set">
								<span>Useful?</span> <i class="iconfont icon-good"></i>/<i
									class="iconfont icon-cai"></i>
								<span>Reply</span>
								<div>Share</div>
							</div>
						</div>
					</div>
				</div>
				<div class="answer">
					<span>{{$t('request.hf')}}</span>
					<textarea v-model="demandReply"
						:placeholder="$t('request.bz1')+info.nickname+$t('request.bz2')"></textarea>
					<div class="post" @click="handsubmit">
						{{$t('request.submit')}}
					</div>
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {
				id: '',
				info: '',
				demandReply: ''
			}
		},
		mounted() {
			this.id = this.$route.query.id
			this.handinfo()
		},
		methods: {
			handvote() {
				this.$request({
					url: '/demand/vote/' + this.id,
					method: 'GET',
					params: {}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.handinfo()
					}
				})
			},
			handsubmit() {
				this.$request({
					url: '/demand/reply',
					data: {
						demandReply: this.demandReply,
						demandId: this.id,

					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.handinfo()
					}
				})
			},
			handinfo() {
				this.$request({
					url: '/demand/demand/' + this.id,
					method: 'GET',
					params: {}
				}).then((res) => {
					if (res.code == 200) {
						this.info = res.data
					}
				})
			},
		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;

				.userinfo {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.user {
						display: flex;
						align-items: center;

						img {
							width: 48px;
							height: 48px;
							border-radius: 50%;
						}

						.info {
							height: 48px;
							display: flex;
							flex-direction: column;
							margin-left: 14px;

							.name {
								font-size: 20px;
								color: #C0C2CC;
								font-weight: 600;
							}

							.time {
								display: flex;
								align-items: center;
								font-size: 16px;
								color: #6B6D76;

								i {
									margin-right: 8px;
								}
							}
						}
					}

					.vote {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 116px;
						height: 129px;
						border-radius: 12px;
						flex-shrink: 0;
						font-size: 12px;
						background: #171C2F;
						color: #6B6D76;
						padding: 10px 0;

						.number {
							font-size: 26px;
							color: #e9e9e9;
							font-weight: 500;

						}

						span {
							color: #C0C2CC;
						}

						.post {
							width: 74px;
							height: 30px;
							border-radius: 6px;
							display: flex;
							align-items: center;
							justify-content: center;
							background: #333647;
							font-size: 14px;
							font-weight: 600;
							color: #C0C2CC;
							margin-top: 6px;
							cursor: pointer;
						}
					}
				}

				.deck {
					font-size: 16px;
					color: #C0C2CC;
					display: flex;
					flex-direction: column;

					span {
						font-size: 36px;
						font-weight: 500;
						color: #E9E9EF;
						margin-bottom: 10px;
					}
				}

				.leave {
					width: 100%;
					display: flex;
					flex-direction: column;
					padding: 40px 0;
					margin-top: 64px;
					border-top: 1px solid rgba(255, 255, 255, 0.06);

					.leavelist {
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						justify-content: space-between;
						margin: 20px 0;

						img {
							width: 48px;
							height: 48px;
							border-radius: 50%;
							background-color: #e9e9e9;
							margin-right: 14px;
							flex-shrink: 0;
						}

						.info {
							width: 100%;
							display: flex;
							flex-direction: column;

							.name {
								font-size: 20px;
								font-weight: 600;
							}

							.time {
								font-size: 16px;
								color: #6B6D76;
							}

							.msg {
								margin: 16px 0;

								span {
									padding: 8px 16px;
									border-radius: 6px;
									font-size: 16px;
									line-height: 26px;
									background: #171C2F;
								}

							}

							.set {
								display: flex;
								font-size: 16px;
								color: #404144;
								align-items: center;

								i {
									margin: 0 16px;
								}

								div {
									margin-left: 16px;
								}
							}
						}
					}
				}

				.answer {
					width: 100%;
					border-radius: 12px;
					display: flex;
					flex-direction: column;
					padding: 30px 40px;
					margin-top: 60px;
					background: #171C2F;

					span {
						font-size: 26px;
						font-weight: 600;
						color: #E9E9EF;
					}

					textarea {
						width: 100%;
						height: 200px;
						border-radius: 6px;
						padding: 14px;
						background: #111523;
						font-size: 16px;
						margin-top: 30px;
					}

					.post {
						width: 112px;
						height: 44px;
						border-radius: 6px;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 14px 22px;
						margin-top: 14px;
						font-size: 16px;
						font-weight: 500;
						background: #333647;
					}
				}
			}

			.featured {
				width: 296px;

				.title {
					font-size: 26px;
					font-weight: 600;
					color: #C0C2CC;
					height: 36px;
					margin-bottom: 20px;
				}

			}
		}




	}
</style>