<template>
	<div id="app">
		<!-- 顶部栏 -->
		<Header ref="Header"></Header>
		<router-view></router-view>
	</div>
</template>

<script>
	import Header from '@/components/Header'
	export default {
		components: {
			Header
		},
		data() {
			return {}
		},
		// 检测路由变化
		watch: {
			$route() {}
		},
		mounted() {},
		methods: {

		},
	}
</script>
<style lang="scss">
	@import url("../static/css/ume.css");
	@import url("../static/css/font/font.css");

	body {
		background-color: #0C0F1C;
		user-select: none;
	}

	#app {
		background-color: #0C0F1C;
	}


	.focus-message {
		background-color: #214745 !important;
		border-color: #214745 !important;
		border-radius: 12px !important;

		.el-message__icon {
			font-size: 24px;
		}

		.el-message__content {
			font-size: 20px !important;
			color: #E9E9EF !important;
		}
	}

	.error-message {
		background-color: #442B3D !important;
		border-color: #442B3D !important;
		border-radius: 12px !important;

		.el-message__icon {
			font-size: 24px;
		}

		.el-message__content {
			font-size: 20px !important;
			color: #E9E9EF !important;
		}
	}

	body::-webkit-scrollbar {
		display: none;
	}
</style>