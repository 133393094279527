<template>
	<div class="main1">
		<div class="links">
			<i class="iconfont icon-home-alt"></i>/<div>Job impact</div>/<div>Data Entry</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="datatitle">
					Data Entry
				</div>
				<div class="entry">
					<div class="info">
						<div class="li">
							<div class="lable">
								AI Impact
							</div>
							<div class="data">
								<div class="progress">
									<div class="number" style="width: 98%;">
										98%
									</div>
								</div>
							</div>
						</div>
						<div class="li">
							<div class="lable">
								Tasks
							</div>
							<div class="data">
								18055
							</div>
						</div>
						<div class="li">
							<div class="lable">
								Als
							</div>
							<div class="data">
								511
							</div>
						</div>
						<div class="desc">
							A Data Entry role involves inputting information into databases, spreadsheets, and digital
							records. Accuracy and attention to detail are crucial. The role may also require organizing
							and maintaining files and communicating with other team members.

							Candidates should have strong typing skills and be comfortable using computer software.
						</div>
					</div>
					<img src="../../../static/images/background/background.png" alt="" />
				</div>
				<div class="toolbox">
					<div class="title">
						Related jobs
					</div>
					<div class="jobs">
						<div class="list">
							<img src="../../../static/images/icon/doubao.png" alt="" />
							<div class="info">
								<div class="jobsname">
									Truss Design
								</div>
								<div class="li">
									Impact: <span>105%</span>
								</div>
								<div class="li">
									Tasks: <span>830</span>
								</div>
								<div class="li">
									Als: <span>9,302</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="table">
					<div class="tr">
						<div class="td">
							#
						</div>
						<div class="td task">
							Job title
						</div>
						<div class="td task">
							Al lmpact<i class="iconfont icon-rongqi1"></i>
						</div>
						<div class="td">
							Tasks
						</div>
						<div class="td">
							Als
						</div>
					</div>
					<div class="tr">
						<div class="td">
							1
						</div>
						<div class="td task">
							Communications Manager
						</div>
						<div class="td task">
							<div class="progress">
								<div class="number" style="width: 98%;">
									98%
								</div>
							</div>
						</div>
						<div class="td">
							511
						</div>
						<div class="td">
							18,023
						</div>
					</div>
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {}
		},
		mounted() {},
		methods: {

		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/DataEntry.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;

				.datatitle {
					font-size: 36px;
					font-weight: 500;
					line-height: 36px;
					color: #E9E9EF;
				}

				.entry {
					width: 100%;
					margin: 50px 0;
					display: flex;
					align-items: flex-start;
					padding-bottom: 40px;
					border-bottom: 1px solid #1A1F34;

					.info {
						width: 100%;
						display: flex;
						flex-direction: column;

						.li {
							width: 100%;
							display: flex;
							align-items: center;
							margin-bottom: 30px;

							.lable {
								width: 118px;
								font-size: 20px;
								color: #6B6D76;
							}

							.data {
								font-size: 20px;
								color: #E9E9EF;

								.progress {
									width: 296px;
									height: 24px;
									border-radius: 4px;
									background: #171C2F;

									.number {
										height: 24px;
										border-radius: 4px;
										display: flex;
										justify-content: center;
										align-items: center;
										background: linear-gradient(270deg, #5F2C76 0%, #2C3E77 100%);
									}
								}
							}
						}

						.desc {
							width: 100%;
							height: 182px;
							font-size: 16px;
							line-height: 26px;
							text-align: justify;
							/* 浏览器可能不支持 */
							letter-spacing: 0em;
							color: #C0C2CC;
						}
					}

					img {
						width: 316px;
						border-radius: 18px;
						margin-left: 48px;
						flex-shrink: 0;
						object-fit: contain;
					}
				}

				.toolbox {
					width: 100%;
					display: flex;
					flex-direction: column;
					margin-bottom: 50px;

					.title {
						font-size: 26px;
						font-weight: 600;
						color: #E9E9EF;
						margin-bottom: 28px;
					}

					.jobs {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex-wrap: wrap;

						.list {
							width: 296px;
							height: 140px;
							border-radius: 12px;
							background: #171C2F;
							display: flex;
							align-items: flex-start;
							flex-direction: row;
							padding: 22px 24px;

							img {
								width: 64px;
								height: 64px;
								border-radius: 12px;
								margin-right: 19px;
							}

							.info {
								display: flex;
								flex-direction: column;

								.jobsname {
									font-size: 20px;
									font-weight: 500;
									color: #C0C2CC;
									margin-bottom: 5px;
								}

								.li {
									display: flex;
									align-items: center;
									font-size: 14px;
									color: #6B6D76;

									span {
										color: #C0C2CC;
									}
								}
							}
						}
					}


				}

				.table {
					width: 100%;
					display: flex;
					flex-direction: column;

					.tr {
						width: 100%;
						height: 64px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-bottom: 1px solid #1A1F34;

						.td {
							width: 100px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 16px;
							color: #C0C2CC;

							img {
								width: 20px;
								height: 20px;
								object-fit: scale-down;
								margin-right: 8px;
							}

							i {
								font-size: 13px;
								margin-left: 8px;
							}

							.progress {
								width: 296px;
								height: 24px;
								border-radius: 4px;
								background: #171C2F;

								.number {
									height: 24px;
									border-radius: 4px;
									display: flex;
									justify-content: center;
									align-items: center;
									background: linear-gradient(270deg, #5F2C76 0%, #2C3E77 100%);
								}
							}
						}

						.task {
							width: 50%;
							justify-content: start;
						}
					}
				}
			}

			.featured {
				width: 296px;

				.title {
					font-size: 26px;
					font-weight: 600;
					color: #C0C2CC;
					height: 36px;
					margin-bottom: 20px;
				}
			}
		}




	}
</style>