import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import router from "./router/index"; // 1、引入配置好的router
// 引入ElementUI组件库
import ElementUI from 'element-ui';
//引入ElementUI的所有样式
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
// 引入图标
import "/static/css/iconfont.css"
// 引入封装好的axios请求
import {
	request,
	Download
} from './utils/axios/http'
// 如果存在多个请求封装则以下方式引入
// import { post, get } from './utils/http'
// 挂载到 Vue 实例
Vue.prototype.$request = request
Vue.prototype.$Download = Download

import localStorage from '@/utils/localStorage'
Vue.prototype.$localStorage = localStorage
import store from '@/utils/vuex-store'
Vue.prototype.$store = store

Vue.config.productionTip = false

import {
	i18n
} from './utils/i18n/index' //国际化

new Vue({
	store,
	router,
	i18n,
	render: h => h(App)
}).$mount('#app')