import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

let store = new Vuex.Store({
	state: {
		login: false,
		loginshow: false,
	},

	mutations: {
		addUser(state, username) {
			state.userList.push(username)
		}
	}
})

export default store