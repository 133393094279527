<template>
	<div class="main1">
		<div class="links">
			<i class="iconfont icon-home-alt"></i>/<div>timeline</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="device">
					<div class="active">Als</div>
					<div>Saves</div>
					<div>A-Z</div>
					<div>New</div>
				</div>
				<div class="pagination">
					<el-pagination background layout="prev, pager, next" :total="1000">
					</el-pagination>
				</div>
				<div class="timeline">
					<div class="titleTime">
						<span>Mar 2024</span>24 new AI capabilities
					</div>
					<el-timeline>
						<el-timeline-item timestamp="2018/4/12" placement="top">
							<div class="list">
								<div class="info">
									<span>Security automation</span>Lid
								</div>
								<div class="link">
									Visit website →
								</div>
							</div>
							<div class="list">
								<div class="info">
									<span>Security automation</span>Lid
								</div>
								<div class="link">
									Visit website →
								</div>
							</div>
						</el-timeline-item>
						<el-timeline-item timestamp="2018/4/3" placement="top">
							<div class="list">
								<div class="info">
									<span>Security automation</span>Lid
								</div>
								<div class="link">
									Visit website →
								</div>
							</div>
						</el-timeline-item>
						<el-timeline-item timestamp="2018/4/2" placement="top">
							<div class="list">
								<div class="info">
									<span>Security automation</span>Lid
								</div>
								<div class="link">
									Visit website →
								</div>
							</div>
						</el-timeline-item>
					</el-timeline>
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {}
		},
		mounted() {},
		methods: {

		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;

				.device {
					width: 378px;
					height: 36px;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 4px;
					padding-right: 24px;
					border: 1px solid rgba(255, 255, 255, 0.19);
					backdrop-filter: blur(10px);

					div {
						min-width: 78px;
						height: 28px;
						padding: 0 10px;
						border-radius: 4px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;

						img {
							width: 20px;
							height: 20px;
							object-fit: scale-down;
							margin-right: 6px;
						}
					}

					.active {
						background: rgba(255, 255, 255, 0.2);
					}
				}

				.pagination {
					margin: 40px 0;

					.el-pagination.is-background .btn-next,
					.el-pagination.is-background .btn-prev,
					.el-pagination.is-background .el-pager li {
						background: none;
						font-size: 20px;
						color: #C0C2CC;
						font-weight: normal;
						margin: 0;
						margin-right: 30px;
						min-width: 40px;
						height: 40px;
						line-height: 40px;
					}

					.el-pagination.is-background .el-pager li:not(.disabled).active {
						background: #3D3F4A;
					}

					.el-pagination.is-background .btn-next.disabled,
					.el-pagination.is-background .btn-next:disabled,
					.el-pagination.is-background .btn-prev.disabled,
					.el-pagination.is-background .btn-prev:disabled,
					.el-pagination.is-background .el-pager li.disabled {
						display: none;
					}
				}

				.timeline {
					width: 100%;
					display: flex;
					flex-direction: column;

					.titleTime {
						display: flex;
						flex-direction: column;
						font-size: 14px;
						color: #C0C2CC;
						margin-bottom: 45px;

						span {
							font-size: 36px;
							font-weight: 500;
							line-height: 36px;
							color: #E9E9EF;
						}
					}

					.list {
						width: 894px;
						height: 96px;
						border-radius: 12px;
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 20px 30px;
						justify-content: space-between;
						background-color: #171C2F;
						margin-bottom: 16px;

						.info {
							display: flex;
							flex-direction: column;
							font-size: 16px;
							color: #C0C2CC;

							span {
								font-size: 24px;
								font-weight: 500;
								color: #E9E9EF;
							}
						}

						.link {
							width: 136px;
							height: 36px;
							border-radius: 4px;
							display: flex;
							justify-content: center;
							align-items: center;
							background: #252B44;
							font-size: 16px;
							font-weight: normal;
							line-height: 16px;
							letter-spacing: 0em;
							font-variation-settings: "opsz" auto;
							color: #9E9E9E;
						}
					}
				}
			}

			.featured {
				width: 296px;

				.title {
					font-size: 26px;
					font-weight: 600;
					color: #C0C2CC;
					height: 36px;
					margin-bottom: 20px;
				}
			}
		}




	}
</style>