<template>
	<div class="main1">
		<div class="links">
			<i class="iconfont icon-home-alt"></i>/<div>{{$t('menu.tasks')}}</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="tasksNav">
					<div class="tagsnav">
						<div :class="tagindex==index?'active':''" v-for="(item,index) in tagsnav" :key="index"
							@click="handtagindex(index)">
							{{item.functionName}}<span></span>
						</div>
					</div>
					<div class="tasks">
						{{$t('menu.tasks')}}
					</div>
				</div>
				<div class="titletga" v-if="tagsnav.length>0">
					<img :src="tagsnav[tagindex].functionImage" alt="" />{{tagsnav[tagindex].functionName}}
				</div>
				<div class="plate">
					<Tool :list="list" :type="1"></Tool>
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {
				tagsnav: [],
				tagindex: 0,
				list: []
			}
		},
		mounted() {
			this.handtype()
		},
		methods: {
			handtagindex(type) {
				this.tagindex = type
				this.handlist()
			},
			handtype() {
				this.$request({
					url: '/function/list',
					method: 'GET',
				}).then((res) => {
					if (res.code == 200) {
						this.tagsnav = res.rows
						this.handlist()
					}
				})
			},
			handlist() {
				this.$request({
					url: '/tool/byFunction',
					method: 'GET',
					params: {
						functionId: this.tagsnav[this.tagindex].functionId,

					}
				}).then((res) => {
					if (res.code == 200) {
						this.list = res.rows
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;

				.tasksNav {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.tagsnav {
						user-select: text;
						width: 100%;
						height: 58px;
						display: flex;
						align-items: center;
						overflow: hidden;
						border-bottom: 3px solid #20242F;
						margin: 60px 0;
						position: sticky;
						top: 0;
						z-index: 3;
						background-color: #0C0F1C;


						div {
							display: flex;
							flex-direction: column;
							align-items: center;
							font-size: 20px;
							color: #6B6D76;
							margin-right: 38px;
							position: relative;
							cursor: pointer;
							flex-shrink: 0;

							span {
								display: none;
								width: 100%;
								height: 3px;
								background: #20242F;
								position: absolute;
								bottom: -15px;
							}
						}

						.active {
							color: #E9E9EF;

							span {
								display: block;
								background: #C0C2CC;
							}
						}
					}

					.tagsnav:hover {
						overflow-x: auto;

						div {
							margin-bottom: -5px;
						}
					}

					// 滚动条样式
					::-webkit-scrollbar {
						width: 5px;
						height: 5px;
						opacity: 0.2;
						position: relative;
						bottom: 0;
					}

					::-webkit-scrollbar-track {
						background-color: rgba(203, 192, 192, .2);
					}

					::-webkit-scrollbar-thumb {
						width: 5px;
						background-color: rgba(203, 192, 192, .2);
						border-radius: 15px;
					}

					// .tagsnav::-webkit-scrollbar {
					// 	display: none;
					// }

					.tasks {
						width: 89px;
						height: 44px;
						background: #333647;
						border-radius: 6px;
						font-size: 16px;
						color: #E9E9EF;
						font-weight: 500;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						margin-left: 24px;
					}
				}

				.titletga {
					font-size: 26px;
					font-weight: 500;
					color: #E9E9EF;
					margin-bottom: 30px;

					img {
						width: 33px;
						height: 33px;
						object-fit: scale-down;
						margin-right: 10px;
					}
				}

				.plate {
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					flex-wrap: wrap;
				}

			}


		}
	}
</style>