<template>
	<div class="main1" ref="scrollToTopElement">
		<div class="links">
			<router-link :to="{name:'index'}"><i class="iconfont icon-home-alt"></i></router-link>/<div>
				{{info.toolName}}
			</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="banner">
					<el-carousel height="210px">
						<el-carousel-item v-for="(item,index) in banner" :key="index">
							<img :src="item.adImage" class="tuozhan" alt="" @click="handclickbanner(item)" />
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="tagsnav">
					<div :class="tagindex==index?'active':''" v-for="(item,index) in tagsnav" :key="index"
						@click="handtagindex(index)">
						{{item}}<span></span>
					</div>
				</div>
				<div class="blurb" id="id0">
					<div class="blurbInfo">
						<div class="blurbTop">
							<div class="tag">
								<img :src="info.functionImage" alt="" />{{info.functionDescription}}<i
									class="el-icon-arrow-right"></i>
							</div>
							<div class="tag" v-if="info.chargeType">
								{{info.chargeType}}
							</div>
							<div class="time">
								<i class="iconfont icon-time"></i>{{info.publishTime}}
							</div>
						</div>
						<div class="blurbcentent">
							<img :src="info.logoImage" alt="" />
							<div class="info">
								<div class="name">
									{{info.toolName}}
								</div>
								<div class="score">
									<div>
										<i class="el-icon-star-off"></i>{{info.rating}}
									</div>
									<div>
										<i class="iconfont icon-xihuan"></i>{{info.collectionCount}}
									</div>
								</div>
							</div>
						</div>
						<div class="blurbDesc">
							{{info.featureDescription}}
						</div>
					</div>
					<div class="blurbset">
						<div :class="info.isCollected==1?'btn1 like':'btn1'" @click.stop="handlike(info.isCollected)">
							<i class="iconfont icon-like1"></i>{{$t('details.save')}}
						</div>
						<div class="btn1">
							<i class="iconfont icon-share"></i>
						</div>
						<a class="btn2" :href="info.websiteLink" target="_blank">
							{{$t('details.fwwz')}} →
						</a>
					</div>
				</div>
				<img :src="info.introductionImage" class="homeimg" alt="" />
				<div class="descInfo">
					<div class="info">
						<div class="tags">
							<div v-for="(item,index) in info.toolFunctions" :key="index">{{item.functionName}}</div>
						</div>
						<div class="text">
							{{$t('details.yjgzs')}}
						</div>
						<div class="look">
							<i class="iconfont icon-deng"></i>{{$t('details.gdgsj')}}
						</div>
						<div class="look">
							<i class="iconfont icon-qh"></i>{{$t('details.recommendations')}}
						</div>
					</div>
					<div class="desc">
						{{info.toolDetails}}
					</div>
				</div>
				<!-- 评分 -->
				<div class="evaluatebox" id="id1">
					<div class="evaluate">
						<div class="title">
							{{$t('details.plpf')}}
						</div>
						<div class="text">
							{{$t('details.bztr')}}
						</div>
						<div class="rate">
							<el-rate v-model="scoreInfo.score" void-color="#C0C2CC"
								void-icon-class="el-icon-star-on"></el-rate>
						</div>
						<div class="title">
							{{$t('details.cppj')}}
						</div>
						<textarea name="" v-model="scoreInfo.scoringCriteria"></textarea>
						<div class="post" @click="handscore">
							{{$t('details.post')}}
						</div>
					</div>
				</div>
				<div class="evaluate">
					<div class="title">
						{{$t('details.gnts')}}
					</div>
					<div class="text">
						{{$t('details.gdxf')}}
					</div>
					<router-link :to="{name:'requests'}" class="post request">
						?? {{$t('details.txq')}}
					</router-link>
				</div>

				<div class="pros" id="id2">
					<div class="title">
						{{$t('details.yhjx')}}
					</div>
					<div class="prosbox">
						<div class="pro">
							<div class="name">
								{{$t('details.pros')}}
							</div>
							<div class="li" v-for="(item,index) in info.ideaList" :key="index" v-if="item.porsName">
								<i class="iconfont icon-good"></i>{{item.porsName}}
							</div>
						</div>
						<div class="pro">
							<div class="name">
								{{$t('details.cons')}}
							</div>
							<div class="li" v-for="(item,index) in info.ideaList" :key="index" v-if="item.consName">
								<i class="iconfont icon-cai"></i>{{item.consName}}
							</div>
						</div>
					</div>
				</div>
				<div class="pros" id="id3">
					<div class="title">
						{{$t('details.cjwt')}}
					</div>
					<el-collapse accordion v-if="info.relatedIssuesList">
						<el-collapse-item v-for="(item,index) in info.relatedIssuesList" :key="index">
							<template slot="title">
								{{item.problem}}
							</template>
							<div>{{item.answer}}</div>
						</el-collapse-item>
					</el-collapse>
				</div>
				<div class="toolbox" id="id4">
					<div class="title">
						{{tool.length}} {{$t('details.xsgj')}}
					</div>
					<Tool :list="tool" :type="1"></Tool>
					<!-- <div class="more">
						More<i class="el-icon-caret-bottom"></i>
					</div> -->
				</div>
				<!-- <div class="toolbox" id="id5">
					<div class="title">
						{{$t('details.xggw')}}
					</div>
					<div class="jobs">
						<div class="list" v-for="(item,index) in info.duJobCategoryList" :key="index">
							<img :src="item.jobImage" alt="" />
							<div class="info">
								<div class="jobsname">
									{{item.jobName}}
								</div>
								<div class="li">
									Impact: <span>105%</span>
								</div>
								<div class="li">
									Tasks: <span>830</span>
								</div>
								<div class="li">
									Als: <span>9,302</span>
								</div>
							</div>
						</div>
					</div>
					<div class="more">
						More<i class="el-icon-caret-bottom"></i>
					</div>
				</div>
				<div class="toolbox" id="id6">
					<div class="title">
						{{$t('details.cnxh')}}
					</div>
					<Tool></Tool>
				</div>
				<div class="pros">
					<div class="title">
						{{$t('details.yhrs')}}
					</div>
					<div class="searchTag">
						<div class="tag">
							<i class="el-icon-search"></i>notes maker
						</div>
						<div class="tag">
							<i class="el-icon-search"></i>notes maker
						</div>
					</div>
				</div> -->
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {
				scoreInfo: {
					score: 0,
					scoringCriteria: '',
				},
				id: '',
				info: '',
				banner: [],
				// tagsnav: [this.$t('details.overview'), this.$t('details.reviews'), this.$t('details.arosCons'), this.$t(
				// 	'details.QA'), this.$t('details.alternatives'), this.$t('details.jobs'), this.$t(
				// 	'details.Seealso'), ],
				tagsnav: [this.$t('details.overview'), this.$t('details.reviews'), this.$t('details.arosCons'), this.$t(
					'details.QA'), this.$t('details.alternatives')],
				tagindex: 0,
				listBoxState: true, //点击导航栏时，暂时停止监听页面滚动
				tool: [], //同类工具
			}
		},
		watch: {
			// 监听路由对象，一旦路由发生变化，就会执行
			'$route': {
				handler: function(to, from) {
					this.id = to.query.id
					this.handdetails()
					this.handproblem()
				},
				// 设置为深度监听
				deep: true
			}
		},
		mounted() {
			let that = this
			that.id = that.$route.query.id
			that.handdetails()
			that.handproblem()
			that.handbanner()
			let timeId;
			window.addEventListener('scroll', () => {
				// 页面滚动停止100毫秒后才会执行下面的函数。
				clearTimeout(timeId);
				timeId = setTimeout(() => {
					that.scrollToTop();
				}, 100);
			}, true);
		},
		methods: {
			// 评分
			handscore() {
				this.$request({
					url: '/subscriber/score',
					data: {
						score: this.scoreInfo.score,
						scoringCriteria: this.scoreInfo.scoringCriteria,
						toolId: this.id
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
					}
				})
			},
			// 轮播图
			handbanner() {
				this.$request({
					url: '/ad/list',
					method: 'GET',
					params: {}
				}).then((res) => {
					if (res.code == 200) {
						this.banner = res.rows
					}
				})
			},
			// 点击轮播图
			handclickbanner(item) {
				window.open(item.adLink, '_blank');
			},
			// 详情
			handdetails() {
				this.$request({
					url: '/tool/' + this.id,
					method: 'GET',
					params: {}
				}).then((res) => {
					if (res.code == 200) {
						this.info = res.data
					}
				})
			},
			// 相关问题
			handproblem() {
				this.$request({
					url: '/tool/function/list',
					method: 'GET',
					params: {
						id: this.id
					}
				}).then((res) => {
					if (res.code == 200) {
						this.tool = res.rows
					}
				})
			},
			// 收藏
			handlike(isCollected) {
				if (!this.$store.state.login) {
					this.$store.state.loginshow = true
					return
				}
				if (isCollected == 0) {
					var url = "/subscriber/add"
				} else {
					var url = "/subscriber/cancel"
				}
				this.$request({
					url: url,
					method: 'GET',
					params: {
						id: this.id,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.handdetails()
					}
				})
			},
			handtagindex(tagindex) {
				this.tagindex = tagindex
				this.$el.querySelector(`#id${tagindex}`).scrollIntoView({
					behavior: "smooth", // 平滑过渡
					block: "start" // 上边框与视窗顶部平齐。默认值
				});
				this.listBoxState = false;

				let timeId;
				clearTimeout(timeId);
				timeId = setTimeout(() => {
					this.listBoxState = true;
				}, 200);
			},
			// 监听页面元素滚动，改变导航栏选中
			scrollToTop() {
				// 获取视窗高度
				var domHight = document.body.offsetHeight;
				// dom滚动位置
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

				if (this.listBoxState) { //作用是点击导航栏时，延迟这里执行。
					this.tagsnav.map((v, i) => {
						// 获取监听元素距离视窗顶部距离
						var offsetTop = document.getElementById(`id${i}`).offsetTop;
						// 获取监听元素本身高度
						var scrollHeight = document.getElementById(`id${i}`).scrollHeight;

						// 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
						// 则表示页面已经滚动到可视区了。
						if (scrollTop >= offsetTop && scrollTop <= (offsetTop + scrollHeight)) {
							// 导航栏背景色选中
							this.tagindex = i;
						}
					})
				}
			},
		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;

				.banner {
					width: 100%;
					height: 210px;
					overflow: hidden;
					border-radius: 30px;

					.tuozhan {
						width: 100%;
						height: 210px;
						object-fit: cover;
					}
				}


				.tagsnav {
					width: 100%;
					height: 58px;
					display: flex;
					align-items: center;
					border-bottom: 3px solid #20242F;
					margin: 60px 0;
					position: sticky;
					top: 0;
					z-index: 3;
					background-color: #0C0F1C;


					div {
						display: flex;
						flex-direction: column;
						align-items: center;
						font-size: 20px;
						color: #6B6D76;
						margin-right: 38px;
						position: relative;
						cursor: pointer;

						span {
							display: none;
							width: 100%;
							height: 3px;
							background: #20242F;
							position: absolute;
							bottom: -15px;
						}
					}

					.active {
						color: #E9E9EF;

						span {
							display: block;
							background: #C0C2CC;
						}
					}
				}

				.blurb {
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.blurbInfo {
						width: 100%;
						display: flex;
						flex-direction: column;

						.blurbTop {
							width: 100%;
							display: flex;
							align-items: center;

							.tag {
								height: 26px;
								border-radius: 4px;
								display: flex;
								justify-content: center;
								align-items: center;
								padding: 6px 8px;
								color: #E9E9EF;
								background: #343848;
								margin-right: 8px;
								font-size: 14px;

								img {
									width: 15px;
									height: 15px;
									margin-right: 2px;
								}

								i {
									font-size: 14px;
									margin-left: 3px;
								}
							}

							.time {
								display: flex;
								align-items: center;
								font-size: 16px;
								color: #6B6D76;
								margin-left: 16px;

								i {
									margin: 0 8px;
								}
							}
						}

						.blurbcentent {
							display: flex;
							align-items: center;
							margin: 32px 0;

							img {
								width: 64px;
								height: 64px;
								border-radius: 12px;
								margin-right: 24px;
							}

							.info {
								height: 64px;
								display: flex;
								flex-direction: column;
								justify-content: space-between;

								.name {
									font-size: 26px;
									font-weight: 500;
									color: #E9E9EF;
								}

								.score {
									display: flex;
									align-items: center;

									div {
										width: 100px;
										display: flex;
										align-items: center;
										font-size: 16px;
										color: #C0C2CC;

										i {
											font-size: 16px;
										}
									}


								}
							}
						}

						.blurbDesc {
							font-size: 16px;
							color: #6B6D76;
						}
					}

					.blurbset {
						display: flex;
						flex-direction: row-reverse;
						align-items: center;

						.btn1 {
							height: 44px;
							border-radius: 6px;
							display: flex;
							align-items: center;
							padding: 14px 22px;
							background: #333647;
							font-size: 16px;
							margin-left: 16px;
							cursor: pointer;
							flex-shrink: 0;
							color: #E9E9EF;

							i {
								font-size: 24px;
								font-weight: bold;
								margin: 0 10px;
							}
						}

						.btn2 {
							width: 205px;
							height: 44px;
							border-radius: 6px;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 14px 22px;
							font-size: 16px;
							color: #E9E9EF;
							background: linear-gradient(103deg, rgba(122, 217, 255, 0.4) 0%, rgba(83, 105, 255, 0.4) 49%, rgba(241, 86, 255, 0.4) 100%);
						}

						.like {
							color: #FF3636;
						}
					}
				}

				.homeimg {
					width: 100%;
					object-fit: scale-down;
					margin: 60px 0;
				}

				.descInfo {
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					margin-bottom: 60px;
					padding-top: 100px;
					margin-top: -100px;

					.info {
						width: 420px;
						display: flex;
						flex-direction: column;

						.tags {
							display: flex;
							align-items: center;
							flex-wrap: wrap;

							div {
								height: 30px;
								border-radius: 6px;
								display: flex;
								justify-content: center;
								align-items: center;
								padding: 8px;
								background: #333647;
								font-size: 14px;
								color: #C0C2CC;
								margin-right: 12px;
								margin-bottom: 12px;
							}
						}

						.text {
							font-size: 16px;
							color: #C0C2CC;
							margin: 14px 0;
						}

						.look {
							width: 100%;
							height: 40px;
							border-radius: 6px;
							display: flex;
							align-items: center;
							padding: 12px 14px;
							font-size: 14px;
							background: #333647;
							color: #C0C2CC;
							margin-bottom: 14px;

							i {
								font-size: 16px;
								margin-right: 8px;
							}
						}
					}

					.desc {
						width: 458px;
						font-size: 14px;
						color: #C0C2CC;
						line-height: 22px;
					}
				}

				.evaluatebox {
					width: 100%;
					padding-top: 100px;
					margin-top: -100px;
				}

				.evaluate {
					width: 100%;
					display: flex;
					flex-direction: column;
					border-radius: 12px;
					background: #171C2F;
					padding: 30px 40px;
					margin-bottom: 50px;

					.title {
						font-size: 26px;
						font-weight: 600;
						color: #E9E9EF;
					}

					.text {
						font-size: 16px;
						color: #6B6D76;
						margin-top: 16px;
					}

					.rate {
						margin: 30px 0;

						.el-rate__icon {
							font-size: 32px;
						}
					}

					textarea {
						width: 454px;
						height: 205px;
						border-radius: 6px;
						background: #111523;
						margin: 9px 0;
						padding: 20px 30px;
					}

					.post {
						width: 112px;
						height: 44px;
						border-radius: 6px;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 14px 22px;
						font-size: 16px;
						font-weight: 500;
						background: #333647;
						cursor: pointer;
					}

					.request {
						width: 206px;
						margin-top: 30px;
						color: #fff;
					}
				}

				.toolbox {
					width: 100%;
					display: flex;
					flex-direction: column;
					margin-bottom: 50px;
					padding-top: 100px;
					margin-top: -100px;

					.title {
						font-size: 26px;
						font-weight: 600;
						color: #E9E9EF;
						margin-bottom: 28px;
					}

					.jobs {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex-wrap: wrap;

						.list {
							width: 296px;
							height: 140px;
							border-radius: 12px;
							background: #171C2F;
							display: flex;
							align-items: flex-start;
							flex-direction: row;
							padding: 22px 24px;

							img {
								width: 64px;
								height: 64px;
								border-radius: 12px;
								margin-right: 19px;
							}

							.info {
								display: flex;
								flex-direction: column;

								.jobsname {
									font-size: 20px;
									font-weight: 500;
									color: #C0C2CC;
									margin-bottom: 5px;
								}

								.li {
									display: flex;
									align-items: center;
									font-size: 14px;
									color: #6B6D76;

									span {
										color: #C0C2CC;
									}
								}
							}
						}
					}

					.more {
						width: 100%;
						height: 40px;
						border-radius: 6px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #333647;
						font-size: 14px;
						color: #C0C2CC;

						i {
							font-size: 16px;
							margin: 0 6px;
						}
					}
				}

				.pros {
					width: 100%;
					display: flex;
					flex-direction: column;
					margin-bottom: 50px;
					padding-top: 100px;
					margin-top: -100px;

					.title {
						font-size: 26px;
						font-weight: 600;
						color: #E9E9EF;
						margin-bottom: 30px;
					}

					.prosbox {
						display: flex;
						align-items: flex-start;

						.pro {
							display: flex;
							flex-direction: column;

							.name {
								font-size: 26px;
								font-weight: 600;
								color: #C0C2CC;
								margin-bottom: 10px;
							}

							.li {
								display: flex;
								align-items: center;
								width: 390px;
								height: 44px;
								font-size: 16px;
								color: #C0C2CC;
								cursor: pointer;

								i {
									font-size: 16px;
									margin-right: 10px;
								}
							}
						}
					}


					.el-collapse {
						border: none;
						osition: relative;
						z-index: 1;
					}

					.el-collapse-item__header {
						width: 100%;
						height: 56px;
						border-radius: 12px;
						display: flex;
						align-items: center;
						padding: 20px;
						background: #171C2F;
						font-size: 16px;
						color: #C0C2CC;
						margin-bottom: 10px;
						border: none;
					}

					.el-collapse-item__wrap {
						background: #171C2F;
						font-size: 16px;
						padding: 20px;
						border: none;
						margin-bottom: 10px;
						border-radius: 12px;
						margin-top: -10px;

						.el-collapse-item__content {
							font-size: 16px;
							color: #C0C2CC;
						}
					}

					.searchTag {
						width: 100%;
						display: flex;
						align-items: center;
						flex-wrap: wrap;

						.tag {
							height: 30px;
							border-radius: 4px;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 6px 14px;
							background: #343848;
							font-size: 14px;
							color: #C0C2CC;
							margin-right: 14px;
							margin-bottom: 14px;
							cursor: pointer;

							i {
								font-size: 18px;
								color: #fff;
								margin-right: 6px;
							}
						}
					}
				}
			}


		}




	}
</style>