<template>
	<div class="main1">
		<div class="links">
			<router-link :to="{name:'index'}"><i class="iconfont icon-home-alt"></i></router-link>/<div>
				{{$t('other.savedals')}}</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="tootitle">
					{{$t('other.saved')}}
				</div>
				<Tool :list="list" :type="1"></Tool>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {
				list: []
			}
		},
		mounted() {
			this.handsaved()
		},
		methods: {
			handsaved() {
				this.$request({
					url: '/subscriber/list',
					method: 'GET',
					params: {

					}
				}).then((res) => {
					if (res.code == 200) {
						this.list = res.data
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;

				.tootitle {
					font-size: 36px;
					font-weight: 500;
					color: #E9E9EF;
					margin-bottom: 25px;
				}


			}

			.featured {
				width: 296px;

				.title {
					font-size: 26px;
					font-weight: 600;
					color: #C0C2CC;
					height: 36px;
					margin-bottom: 20px;
				}

			}
		}




	}
</style>