import Vue from 'vue' //引入vue js是一个vue的配置文件 vue版本是2.6
import Router from 'vue-router' //vue-router 安装的对象 npm install vue-router@"3.0.1"
import index from '@/pages/index'
import details from '@/pages/details/details'
import requests from '@/pages/requests/requests'
import vote from '@/pages/requests/vote'
import newrequests from '@/pages/requests/newrequests'
import tasks from '@/pages/tasks/tasks'
import timeline from '@/pages/timeline/timeline'
import job from '@/pages/job/job'
import data from '@/pages/job/data'
import saved from '@/pages/saved/saved'


//vue中使用Router
Vue.use(Router)
const routers = [ //配置router与组件之间的对应关系
	{
		path: '/',
		name: 'index',
		component: index
	},
	{
		path: '/details',
		name: 'details',
		component: details
	},
	{
		path: '/requests',
		name: 'requests',
		component: requests
	},
	{
		path: '/requests/vote',
		name: 'vote',
		component: vote
	},
	{
		path: '/requests/newrequests',
		name: 'newrequests',
		component: newrequests
	},
	{
		path: '/tasks',
		name: 'tasks',
		component: tasks
	},
	{
		path: '/timeline',
		name: 'timeline',
		component: timeline
	},
	{
		path: '/job',
		name: 'job',
		component: job
	},
	{
		path: '/job/data',
		name: 'data',
		component: data
	},
	{
		path: '/saved/saved',
		name: 'saved',
		component: saved
	},
]
//创建一个vue-router实例对象
const router = new Router({
	mode: "hash", //history 不带#号 hash 带#号
	routes: routers //所有的路由数组
})
const VueRouterPush = Router.prototype.push

Router.prototype.push = function push(to) {

	return VueRouterPush.call(this, to).catch(err => err)

}
router.beforeEach((to, from, next) => {
	// 获取到前面打过标签的元素，将其scrollTop属性设置为0
	document.documentElement.scrollTop = 0
	// 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
	document.body.scrollTop = 0
	// 调用继续路由跳转
	next()
})
//导出路由对象  使用路由的地方引入即可  一般在入口main.js中使用
export default router;