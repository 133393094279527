<template>
	<div class="main">
		<video src="../../static/video/newbg.mp4" class="newbg" muted loop autoplay></video>
		<div class="search_main">
			<div class="title" v-if="locale=='en'">
				DISCOVER YOUR <br />
				NEXTGENERATIVE AL TOOL
			</div>
			<div class="title" v-else>
				AISPOTLIGHT
			</div>
			<div class="text" v-if="locale=='en'">
				A very simple and easy-to-use AI tool navigation
			</div>
			<div class="text2" v-else>
				简单易用的人工智能导航利器
			</div>
			<div class="search">
				<i class="el-icon-search" v-if="!loading"></i>
				<i class="el-icon-loading" v-else></i>
				<el-input type="text" :placeholder="$t('index.ssts')" v-model="keyword" @focus="versionCategory=''"
					@keyup.enter.native="handdata" @input="handcandidate" /></el-input>
			</div>
			<div class="searchbox" v-if="candidate.length>0&&keyword">
				<div class="li" v-for="(item,index) in candidate" :key="index" @click="handselect(item)">
					{{item}}
				</div>
			</div>
		</div>
		<div class="substance">
			<div class="basic">
				<div class="sort">
					<div class="device">
						<div :class="versionCategory==''?'active':''" @click="handselectsearch('',1)">All</div>
						<div :class="versionCategory=='1'?'active':''" @click="handselectsearch('1',1)"><img
								src="../../static/images/icon/Chrome.png" alt="" /> {{$t('index.chrome')}}</div>
						<div :class="versionCategory=='2'?'active':''" @click="handselectsearch('2',1)"><img
								src="../../static/images/icon/ios.png" alt="" /> {{$t('index.ios')}}</div>
						<div :class="versionCategory=='3'?'active':''" @click="handselectsearch('3',1)"><img
								src="../../static/images/icon/android.png" alt="" />{{$t('index.android')}}</div>
					</div>
					<div class="time">
						<div :class="searchtype==1?'active':''" @click="handselectsearch(1,2)"><i
								class="iconfont icon-time"></i>{{$t('index.new')}}</div>
						<div :class="searchtype==2?'active':''" @click="handselectsearch(2,2)"><i
								class="iconfont icon-most"></i>{{$t('index.saved')}}</div>
					</div>
				</div>
				<div class="plate">
					<Tool :list="list" :type="1"></Tool>
				</div>
				<div class="more" @click="handscrollload" v-if="list.length>0&&!flag">
					{{$t('index.more')}}
					<i class="el-icon-caret-bottom" v-if="!loading"></i>
					<i class="el-icon-loading" v-else></i>
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {
				pageNum: 1,
				pageSize: 15,
				flag: false,
				list: [],
				keyword: '', //查询词
				versionCategory: '', //版本类别
				searchtype: 1, //1，时间  2，收藏
				loading: false,
				candidate: [], //搜索提示
				locale: localStorage.getItem("locale") || 'zh'
			}
		},
		mounted() {
			this.handdata()
		},
		methods: {
			handscrollload() {
				this.pageNum++
				this.handlist()
			},
			handdata() {
				this.flag = false
				this.pageNum = 1
				this.list = []
				this.handlist()
			},
			handselect(keyword) {
				this.keyword = keyword
				this.candidate = []
				this.handdata()
			},

			handselectsearch(item, type) {
				if (type == 1) {
					this.versionCategory = item
				} else {
					this.searchtype = item
				}
				this.handdata()
			},
			handlist() {
				this.loading = true
				this.$request({
					url: '/tool/search',
					method: 'GET',
					params: {
						keyword: this.keyword,
						versionCategory: this.versionCategory,
						publishTime: this.searchtype == 1 ? true : '',
						collection: this.searchtype == 2 ? true : '',
						pageNum: this.pageNum,
						pageSize: this.pageSize,

					}
				}).then((res) => {
					if (res.code == 200) {
						this.loading = false
						this.list = [...this.list, ...res.rows]
						if (this.list.length < this.pageNum * this.pageSize) {
							this.flag = true
						}
					}
				})
			},
			// 搜索提示
			handcandidate() {
				this.$request({
					url: '/tool/candidate',
					method: 'GET',
					params: {
						keyword: this.keyword,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.candidate = res.rows
					}
				})
			},
		}
	}
</script>

<style lang="scss">
	.main {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.newbg {
			width: 100%;
			position: fixed;
			top: 0;
			z-index: 0;
		}

		.search_main {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 430px;
			min-width: 530px;
			margin-top: 60px;
			position: relative;
			z-index: 2;

			.title {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-family: DingTalk JinBuTi;
				font-size: 48px;
				font-weight: normal;
				line-height: 50px;
				text-align: center;
				text-transform: uppercase;
				font-variation-settings: "opsz" auto;
				font-feature-settings: "kern" on;
				background: linear-gradient(100deg, #7AD9FF 0%, #5369FF 49%, #F156FF 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}

			.text {
				opacity: 0.8;
				font-family: MiSans;
				font-size: 18px;
				font-weight: normal;
				line-height: 24px;
				text-align: center;
				letter-spacing: 0em;
				font-variation-settings: "opsz" auto;
				font-feature-settings: "kern" on;
				color: #FFFFFF;
				margin: 10px 0;
			}

			.text2 {
				opacity: 0.8;
				font-family: DingTalk JinBuTi;
				font-size: 24px;
				font-weight: 300;
				line-height: 24px;
				text-align: center;
				letter-spacing: 2px;

				font-variation-settings: "opsz" auto;
				font-feature-settings: "kern" on;
				color: #FFFFFF;
				margin: 10px 0;
			}

			.search {
				border-radius: 12px;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 20px 0;
				padding: 16px 22px;
				gap: 12px;
				align-self: stretch;
				background: rgba(255, 255, 255, 0.16);
				box-sizing: border-box;
				border: 1px solid rgba(255, 255, 255, 0.2);
				backdrop-filter: blur(10px);
				box-shadow: 0px 12px 36px 0px rgba(150, 150, 161, 0.29);

				i {
					font-size: 18px;
					color: #fff;
					flex-shrink: 0;
				}

				.el-input {
					.el-input__inner {
						background: none;
						border: none;
						color: #fff;
					}

					width: 100%;
				}
			}

			.searchbox {
				width: 100%;
				border-radius: 12px;
				display: flex;
				flex-direction: column;
				padding: 0 24px;
				background-color: #171C2F;
				position: relative;
				z-index: 7;

				.li {
					width: 100%;
					height: 55px;
					display: flex;
					align-items: center;
					font-size: 16px;
					color: #E9E9EF;
					border-bottom: 1px solid #303449;
					cursor: pointer;
					opacity: 0.6;
				}

				.li:hover {
					opacity: 1;
					// background: #25293B;
				}
			}
		}

		.substance {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.basic {
				width: 928px;
				display: flex;
				flex-direction: column;

				.sort {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 20px;
					height: 36px;

					.device {
						width: 430px;
						height: 36px;
						border-radius: 8px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 4px;
						padding-right: 24px;
						border: 1px solid rgba(255, 255, 255, 0.19);
						backdrop-filter: blur(10px);

						div {
							min-width: 78px;
							height: 28px;
							padding: 0 10px;
							border-radius: 4px;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;

							img {
								width: 20px;
								height: 20px;
								object-fit: scale-down;
								margin-right: 6px;
							}
						}

						.active {
							background: rgba(255, 255, 255, 0.2);
						}
					}

					.time {
						display: flex;
						justify-content: center;

						div {
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							margin-left: 30px;
							font-size: 16px;
							opacity: 0.5;
							color: #C0C2CC;

							i {
								font-size: 20px;
								color: #C0C2CC;
								margin-right: 8px;
							}
						}

						.active {
							opacity: .99;
						}
					}
				}

				.plate {
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					flex-wrap: wrap;
				}

				.more {
					width: 100%;
					height: 40px;
					border-radius: 6px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #333647;
					font-size: 14px;
					color: #C0C2CC;
					cursor: pointer;
					margin-bottom: 30px;
					position: relative;
					z-index: 9;

					i {
						font-size: 16px;
						margin: 0 6px;
					}
				}

				.more:hover {
					background-color: #171C2F;
				}
			}

		}
	}
</style>