import { typeOf } from './utils'
const storage = window.localStorage
const setItem = (key, value) => {
  if (Object.is(typeOf(value), 'object')) {
    let d = JSON.stringify(value)
    return storage.setItem(key, d)
  }
  storage.setItem(key, value)
}
const getItem = (key) => {
  if (!Object.is(typeOf(key), 'string')) {
    return false
  }
  if (Object.is(/^{(.*)}$/.test(storage.getItem(key)), true)) {
    return JSON.parse(storage.getItem(key))
  }
  return storage.getItem(key)
}

const removeItem = (key) => {
  return storage.removeItem(key)
}

const clear = () => storage.clear()

export default {
  setItem,
  getItem,
  removeItem,
  clear
}
