<template>
	<div class="main1">
		<div class="links">
			<router-link :to="{name:'index'}"><i class="iconfont icon-home-alt"></i></router-link>/<div>
				{{$t('menu.request')}}
			</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="tootitle">
					{{$t('request.gjts')}}
				</div>
				<div class="toodesc">
					{{$t('request.xqbz')}}
				</div>
				<div class="sort">
					<div class="device">
						<div :class="type==1?'active':''" @click="handtype(1)">{{$t('request.dzd')}}</div>
						<div :class="type==2?'active':''" @click="handtype(2)">{{$t('request.zx')}}</div>
					</div>
					<router-link :to="{name:'newrequests'}" class="new">
						<i class="el-icon-circle-plus-outline"></i>
						{{$t('request.txq')}}
					</router-link>
				</div>
				<div class="request" @click="handlink(item.demandId)" v-for="(item,index) in list" :key="index">
					<div class="vote">
						<div class="number">
							{{item.demandPoll}}
						</div>
						vote
						<span> {{item.demandReplySum}} answers</span>
						<div class="post">
							VOTE
						</div>
					</div>
					<div class="info">
						<div class="name">
							{{item.demandTitle}}
						</div>
						<div class="content">
							<div>{{item.demandTag}}</div>{{item.nickname}}·{{item.demandTime}}
						</div>
					</div>
				</div>
				<div class="more" @click="handscrollload" v-if="list.length>0&&!flag">
					{{$t('index.more')}}
					<i class="el-icon-caret-bottom" v-if="!loading"></i>
					<i class="el-icon-loading" v-else></i>
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {
				type: 1,
				pageNum: 1,
				pageSize: 10,
				flag: false,
				loading: false,
				list: []
			}
		},
		mounted() {
			this.handdata()
		},
		methods: {
			handscrollload() {
				this.pageNum++
				this.handlist()
			},
			handtype(type) {
				this.type = type
				this.handdata()
			},
			handdata() {
				this.flag = false
				this.pageNum = 1
				this.list = []
				this.handlist()
			},
			handlist() {
				this.loading = true
				this.$request({
					url: '/demand/allList',
					method: 'GET',
					params: {
						idSelf: '0',
						demandPoll: this.type == 1 ? '1' : '',
						demandTime: this.type == 2 ? '1' : '',
						pageNum: this.pageNum,
						pageSize: this.pageSize,

					}
				}).then((res) => {
					if (res.code == 200) {
						this.loading = false
						this.list = [...this.list, ...res.rows]
						if (this.list.length < this.pageNum * this.pageSize) {
							this.flag = true
						}
					}
				})
			},
			handlink(id) {
				this.$router.push({
					name: 'vote',
					query: {
						id: id
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;

				.tootitle {
					font-size: 36px;
					font-weight: 500;
					color: #E9E9EF;
					margin-bottom: 25px;
				}

				.toodesc {
					font-size: 16px;
					line-height: 26px;
					color: #C0C2CC;
				}

				.sort {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 32px;
					margin-bottom: 20px;
					height: 36px;

					.device {
						width: 224px;
						height: 36px;
						border-radius: 8px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 4px;
						padding-right: 24px;
						border: 1px solid rgba(255, 255, 255, 0.19);
						backdrop-filter: blur(10px);

						div {
							min-width: 78px;
							height: 28px;
							padding: 0 10px;
							border-radius: 4px;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;

							img {
								width: 20px;
								height: 20px;
								object-fit: scale-down;
								margin-right: 6px;
							}
						}

						.active {
							background: rgba(255, 255, 255, 0.2);
						}
					}

					.new {
						width: 183px;
						height: 44px;
						border-radius: 6px;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 14px 20px;
						font-size: 16px;
						color: #fff;
						font-weight: 500;
						background: linear-gradient(105deg, rgba(122, 217, 255, 0.4) 0%, rgba(83, 105, 255, 0.4) 49%, rgba(241, 86, 255, 0.4) 100%);

						i {
							font-size: 24px;
							margin-right: 10px;
						}
					}
				}

				.request {
					width: 100%;
					height: 129px;
					border-radius: 12px;
					display: flex;
					align-items: center;
					background: #171C2F;
					margin-bottom: 24px;

					.vote {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 116px;
						height: 129px;
						border-right: 2px solid #0C0F1C;
						flex-shrink: 0;
						font-size: 12px;
						color: #6B6D76;
						padding: 10px 0;

						.number {
							font-size: 26px;
							color: #e9e9e9;
							font-weight: 500;

						}

						span {
							color: #C0C2CC;
						}

						.post {
							width: 74px;
							height: 30px;
							border-radius: 6px;
							display: flex;
							align-items: center;
							justify-content: center;
							background: #333647;
							font-size: 14px;
							font-weight: 600;
							color: #C0C2CC;
							margin-top: 6px;
						}
					}

					.info {
						padding: 0 30px;

						.name {
							font-size: 26px;
							font-weight: 500;
							color: #E9E9EF;
						}

						.content {
							display: flex;
							align-items: center;
							font-size: 16px;
							color: #6B6D76;
							margin-top: 12px;

							div {
								width: 65px;
								height: 30px;
								border-radius: 6px;
								display: flex;
								align-items: center;
								justify-content: center;
								background: #333647;
								font-size: 14px;
								color: #C0C2CC;
								margin-right: 12px;
							}
						}
					}
				}

				.more {
					width: 100%;
					height: 40px;
					border-radius: 6px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #333647;
					font-size: 14px;
					color: #C0C2CC;
					cursor: pointer;
					margin-bottom: 30px;

					i {
						font-size: 16px;
						margin: 0 6px;
					}
				}

				.more:hover {
					background-color: #171C2F;
				}
			}

			.featured {
				width: 296px;

				.title {
					font-size: 26px;
					font-weight: 600;
					color: #C0C2CC;
					height: 36px;
					margin-bottom: 20px;
				}

			}
		}




	}
</style>