module.exports = {
	langage: {
		ch: "CH",
		en: "EN",
	},
	menu: {
		today: '新品',
		request: '发现',
		mostSaved: '收藏最多',
		tasks: '功能分类',
		timeline: '时间线',
		getfeatured: '精选',
		jobimpach: '工作影响指数',
		infurencers: '影响者',
		followUS: '联系我们',
		login: '登录',
		Logout: '退出登录',
	},
	index: {
		ssts: '搜索您所需要的AI工具',
		more: '加载更多',
		featured: '推荐',
		chrome: '网页版',
		ios: '苹果',
		android: '安卓',
		new: '最新',
		saved: '热门收藏',
	},
	details: {
		overview: '简介',
		reviews: '评价',
		arosCons: '特点',
		QA: '问答',
		alternatives: '相关',
		jobs: '岗位',
		Seealso: '同趣',
		fwwz: '访问网站',
		save: '收藏',
		yjgzs: 'AIspotlight研究工作室',
		gdgsj: '更多工具更新中',
		recommendations: '推荐',
		plpf: '评分及评论',
		bztr: '帮助他人了解产品是否有用',
		cppj: '产品评价',
		post: '提交',
		gnts: '功能探索',
		gdxf: "有更多需要的功能想法",
		txq: '提需求',
		yhjx: ' 用户精选',
		pros: '点赞',
		cons: '拍砖',
		cjwt: '常见问题',
		xsgj: '相似的AI工具',
		xggw: '相关岗位',
		cnxh: '猜你喜欢',
		yhrs: '用户热搜',
	},
	request: {
		gjts: 'AI工具探索',
		xqbz: '如果没有找到您想要的AI工具，可在此处描述功能需求，以寻求帮助。',
		dzd: '点赞多',
		zx: '最新',
		txq: '提需求',
		xqtj: '需求提交',
		wtlx: '问题类型',
		xzgj: '寻找AI工具',
		zdtj: '针对指定工具提出新的功能需求',
		qt: '其他',
		title: '标题',
		xqlx: '需求类型',
		xxsm: '详细说明',
		xqms: '请在此处描述需求内容',
		submit: '提交',
		hf: '回复',
		bz1: '你能帮助',
		bz2: "找到他们要找的东西吗?在此发表您的想法。",
	},
	other: {
		address: '公司信息',
		phone: '联系方式',
		mail: '其他',
		zlogin: '账户登录',
		email: '邮箱地址',
		password: '密码',
		login: '登录',
		code: '验证码',
		confirm: '确认',
		emailLogin: '邮箱登录',
		wxLogin: '使用微信登录',
		wjmm: '忘记密码',
		wzzye: '未注册账号，直接进入注册页面',
		hyyzm: '获取验证码',
		dd: '等待',
		s: '秒',
		zc: '注册',
		csmm: '重设密码',
		savedals: '收藏工具',
		saved: '已收藏',
	}
}