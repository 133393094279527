<template>
	<div class="header_main">
		<div class="header">
			<div class="logo">
				<img src="../../static/images/icon/logo.png" alt="" /><span>AI</span>spotlight
			</div>
			<div class="link">
				<router-link :to="{name:'index'}"
					:class="linkActive==''?'li active':'li'">{{$t('menu.today')}}</router-link>
				<router-link :to="{name:'tasks'}"
					:class="linkActive=='tasks'?'li active':'li'">{{$t('menu.tasks')}}</router-link>
				<router-link :to="{name:'requests'}"
					:class="linkActive=='requests'?'li active':'li'">{{$t('menu.request')}}</router-link>
				<!-- 	<router-link :to="{name:'index'}"
					:class="linkActive=='index'?'li active':'li'">{{$t('menu.mostSaved')}}</router-link> -->

				<!-- <router-link :to="{name:'timeline'}"
					:class="linkActive=='timeline'?'li active':'li'">{{$t('menu.timeline')}}</router-link>
				<router-link :to="{name:'index'}"
					:class="linkActive=='index'?'li active':'li'">{{$t('menu.getfeatured')}}</router-link>
				<router-link :to="{name:'job'}"
					:class="linkActive=='job'?'li active':'li'">{{$t('menu.jobimpach')}}</router-link>
				<router-link :to="{name:'index'}"
					:class="linkActive=='index'?'li active':'li'">{{$t('menu.infurencers')}}</router-link> -->
				<div @click="aboutshow=true" :class="linkActive=='index'?'li active':'li'">{{$t('menu.followUS')}}</div>
			</div>
			<div class="login" @click="$store.state.loginshow=true" v-if="!$store.state.login">
				{{$t('menu.login')}}
			</div>
			<div class="userinfo" v-else>
				<router-link :to="{name:'saved'}" class="user" v-if="linkActive!='saved'">
					<img src="../../static/images/icon/user.png" alt="" />{{username}}
				</router-link>
				<div class="login" @click="handLogOut" v-else>
					{{$t('menu.Logout')}}
				</div>
			</div>

		</div>
		<el-backtop></el-backtop>
		<div class="loginBox" v-if="$store.state.loginshow">
			<div class="background"></div>
			<!-- 登录 -->
			<div class="box" v-if="loginType==1">
				<div class="close">
					<i class="el-icon-close" @click="$store.state.loginshow=false"></i>
				</div>
				<div class="title">
					<span>{{$t('other.zlogin')}}</span>{{$t('other.wzzye')}}
				</div>
				<div class="name">
					{{$t('other.email')}}
				</div>
				<div class="li">
					<i class="iconfont icon-email"></i><input type="text" v-model="form.username"
						placeholder="Your email" />
				</div>
				<div class="name">
					{{$t('other.password')}}
				</div>
				<div class="li">
					<i class="iconfont icon-password"></i><input type="password" v-model="form.password"
						placeholder="Your password" />
				</div>
				<div class="links">
					<div class="link" @click="loginType=4">
						{{$t('other.wjmm')}}
					</div>
				</div>
				<div class="btns" @click="handlogin">
					{{$t('other.login')}}
				</div>
				<div class="btns wxbtn" @click="handwxlogin">
					<img src="../../static/images/icon/wx.png" alt="" />{{$t('other.wxLogin')}}
				</div>
			</div>
			<!-- 注册 -->
			<div class="box" v-if="loginType==2">
				<div class="close">
					<i class="el-icon-close" @click="$store.state.loginshow=false"></i>
				</div>
				<div class="title">
					<span>Register</span>
				</div>
				<div class="name">
					{{$t('other.email')}}
				</div>
				<div class="li">
					<i class="iconfont icon-email"></i><input type="text" v-model="form.username"
						placeholder="Your email" />
				</div>
				<div class="name">
					{{$t('other.code')}}
				</div>
				<div class="li">
					<i class="iconfont icon-password"></i><input type="text" v-model="form.code"
						placeholder="Email Code" />
					<div class="getcode" v-if="btnDisabled==0" @click="getcode"> {{$t('other.hyyzm')}}</div>
					<div class="getcode" v-if="btnDisabled==1">{{$t('other.dd')}}{{second}}{{$t('other.s')}}</div>
				</div>
				<div class="name">
					{{$t('other.password')}}
				</div>
				<div class="li">
					<i class="iconfont icon-password"></i><input type="password" v-model="form.password"
						placeholder="Your password" />
				</div>
				<div class="links">
					<div class="link" @click="loginType=1">
						{{$t('other.login')}}
					</div>
				</div>
				<div class="btns" @click="handRegister">
					{{$t('other.zc')}}
				</div>
			</div>
			<!-- 忘记密码 -->
			<div class="box" v-if="loginType==4">
				<div class="close">
					<i class="el-icon-close" @click="$store.state.loginshow=false"></i>
				</div>
				<div class="title">
					<span>{{$t('other.csmm')}}</span>
				</div>
				<div class="name">
					{{$t('other.email')}}
				</div>
				<div class="li">
					<i class="iconfont icon-email"></i><input type="text" v-model="form.username"
						placeholder="Your email" />
				</div>
				<div class="name">
					{{$t('other.code')}}
				</div>
				<div class="li">
					<i class="iconfont icon-password"></i><input type="text" v-model="form.code"
						placeholder="Email Code" />
					<div class="getcode" v-if="btnDisabled==0" @click="getcodeconfirm"> {{$t('other.hyyzm')}}</div>
					<div class="getcode" v-if="btnDisabled==1">{{$t('other.dd')}}{{second}}{{$t('other.s')}}</div>
				</div>
				<div class="name">
					{{$t('other.password')}}
				</div>
				<div class="li">
					<i class="iconfont icon-password"></i><input type="password" v-model="form.password"
						placeholder="Your password" />
				</div>
				<div class="links">
					<div class="link" @click="loginType=1">
						{{$t('other.login')}}
					</div>
				</div>
				<div class="btns" @click="handconfirm">
					{{$t('other.confirm')}}
				</div>
			</div>
			<!-- 微信扫码 -->
			<div class="box" v-show="loginType==3">
				<div class="close">
					<i class="el-icon-close" @click="$store.state.loginshow=false"></i>
				</div>
				<div id="login_container" class="code"> </div>
				<div class="links">
					<div class="link" @click="loginType=1">
						{{$t('other.emailLogin')}}
					</div>
				</div>
			</div>
		</div>
		<div class="loginBox" v-if="aboutshow">
			<div class="background"></div>
			<div class="about">
				<div class="close">
					<i class="el-icon-close" @click="aboutshow=false"></i>
				</div>
				<div class="us">
					{{$t('menu.followUS')}}
				</div>
				<div class="title">
					<span>{{$t('other.address')}}</span>{{about.companyAddress}}
				</div>
				<div class="title">
					<span>{{$t('other.phone')}}</span>{{about.contactInformation}}
				</div>
				<div class="title">
					<span>{{$t('other.mail')}}</span>{{about.contactMail}}
				</div>
			</div>
		</div>
		<div class="filing">
			<a href="https://beian.miit.gov.cn/">
				<div class="text">沪ICP备2024054091号-2</div>
			</a>
		</div>
	</div>
	</div>
</template>

<script>
	import weixin from "../utils/WxLogin.js"
	export default {
		data() {
			return {
				loginType: 1, //1,登录 2，注册 3,微信扫码4,忘记密码
				form: {
					username: '',
					password: '',
					code: '',
					uuid: '',
				},
				username: this.$localStorage.getItem('userName') || '已登录',
				btnDisabled: 0,
				second: 60,
				aboutshow: false,
				about: '',
				linkActive: ''
			}
		},
		// 检测路由变化
		watch: {
			$route() {
				this.setCurrentRoute();
			}
		},
		mounted() {
			if (this.$localStorage.getItem('token')) {
				this.$store.state.login = true
			}
			this.handabout()
			this.handtoken()
		},
		methods: {
			setCurrentRoute() {
				var path = this.$route.path
				this.linkActive = path.split('/')[1] // 通过他就可以监听到当前路由状态并激活当前菜单
				console.log(this.linkActive)
			},
			handtoken() {
				console.log(this.$route)
				if (this.$route.query.token) {
					this.$localStorage.setItem('userName', this.$route.query.nickname)
					this.username = this.$route.query.nickname
					this.$localStorage.setItem('token', this.$route.query.token)
					this.$store.state.loginshow = false
					this.$store.state.login = true
					// window.location.href = window.location.origin
				}
			},
			handwxlogin() {
				let url = window.location.hash.replace(/^#\//, '');
				// window.open(
				// 	'https://open.weixin.qq.com/connect/qrconnect?appid=wx689043acca2479ed&redirect_uri=http://aisportlight.app12345.cn/wechat&response_type=code&scope=snsapi_login&state=' +
				// 	url);
				this.loginType = 3
				// 微信登录第一步：申请微信登录二维码
				console.log(url)
				new WxLogin({
					id: "login_container", // 二维码显示的位置
					appid: "wx689043acca2479ed", // 这个appid要填死
					scope: "snsapi_login", //
					// 扫码成功后重定向的接口	
					redirect_uri: encodeURIComponent("http://boot.aispotlight.cn/wechat"),
					state: encodeURIComponent(url),
					style: 'white',
					// 调用样式文件
					// href: "data:text/css;base64,LmJveHsKICBiYWNrZ3JvdW5kOmJsb2NrOwp9",
				});
			},
			handabout() {
				this.$request({
					url: '/about/list',
					method: 'GET',
					params: {}
				}).then((res) => {
					if (res.code == 200) {
						this.about = res.rows[0]
					}
				})
			},
			handLogOut() {
				this.$store.state.login = false
				this.$router.push({
					name: 'index'
				})
				this.$localStorage.clear()
				location.reload();
			},
			// 获取验证码
			getcode() {
				this.$request({
					url: '/email',
					method: 'GET',
					params: {
						recipientMail: this.form.username,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.form.uuid = res.uuid
						this.timer()
					}
				})
			},
			// 重置密码获取验证码
			getcodeconfirm() {
				this.$request({
					url: '/emailValidate',
					method: 'GET',
					params: {
						recipientMail: this.form.username,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.form.uuid = res.uuid
						this.timer()
					}
				})
			},
			// 倒计时
			timer() {
				this.btnDisabled = 1
				var interval = setInterval(() => {
					let times = --this.second
					this.second = times < 10 ? '0' + times : times //小于10秒补 0
				}, 1000)
				setTimeout(() => {
					clearInterval(interval)
					this.second = 60
					this.btnDisabled = 0
				}, 60000)
			},
			// 修改密码
			handconfirm() {
				this.$request({
					url: '/system/user/profile/updatePwdByEmail',
					data: this.form
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.loginType = 1
					}
				})
			},
			handRegister() {
				this.$request({
					url: '/register',
					data: this.form
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.loginType = 1
					}
				})
			},
			handlogin() {
				this.$request({
					url: '/login',
					data: this.form
				}).then((res) => {
					console.log(res, 564546546)
					if (res.code == 500) {
						if (res.msg == "用户不存在") {
							this.loginType = 2
							this.getcode()
							return false
						}
					}
					if (res.code == 200) {
						this.$localStorage.setItem('token', res.token)
						const match = this.form.username.match(/^([^@]*)/);
						if (match && match[1]) {
							this.$localStorage.setItem('userName', match[1])
						}
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						this.$store.state.loginshow = false
						location.reload();
					}
				})
			},
		}
	}
</script>

<style lang="scss">
	.header_main {
		width: 100%;
		height: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		z-index: 9;

		.header {
			width: 1288px;
			height: 60px;
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;

			.logo {
				display: flex;
				align-items: center;
				font-family: DingTalk JinBuTi;
				font-size: 20px;
				font-variation-settings: "opsz" auto;
				font-feature-settings: "kern" on;
				color: #FFFFFF;

				span {
					font-size: 28px;
				}

				img {
					width: 32px;
					height: 32px;
					object-fit: scale-down;
					margin-right: 5px;
				}
			}

			.link {
				display: flex;
				flex-direction: row;
				align-items: center;

				.li {
					margin: 0 18px;
					font-size: 16px;
					color: #6B6D76;
					cursor: pointer;
				}

				.li::first-letter {
					text-transform: uppercase;
				}

				.li:hover,
				.active {
					color: #C0C2CC;
				}
			}

			.login {
				width: 87px;
				cursor: pointer;
				height: 32px;
				border-radius: 6px;
				border: 1px solid rgba(192, 194, 204, 0.6);
				font-weight: 500;
				font-size: 16px;
				color: #C0C2CC;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.user {
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: 500;
				color: #C0C2CC;

				img {
					width: 20px;
					height: 20px;
					border-radius: 50%;
					margin-right: 8px;
					object-fit: contain;
				}
			}
		}

		.loginBox {
			width: 100vw;
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			z-index: 8;

			.background {
				background-color: rgba(0, 0, 0, 0.7);
				position: fixed;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
				z-index: 9;
			}

			.box {
				width: 496px;
				background: #171C2F;
				border: 2px solid #2E3241;
				z-index: 99;
				border-radius: 18px;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 0 34px;

				.close {
					width: 100%;
					display: flex;
					flex-direction: row-reverse;
					margin-top: 20px;

					i {
						font-size: 20px;
						color: #9E9E9E;
						cursor: pointer;
					}
				}

				.code {
					width: 400px;
					height: 400px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.title {
					width: 100%;
					display: flex;
					flex-direction: column;
					font-size: 14px;
					color: #9E9E9E;
					margin-top: 7px;
					margin-bottom: 60px;

					span {
						font-size: 26px;
						color: #E9E9EF;
						font-weight: 600;
						margin-bottom: 14px;
					}
				}

				.name {
					width: 100%;
					font-size: 14px;
					color: #9E9E9E;
					margin-bottom: 8px;
				}

				.li {
					width: 100%;
					height: 50px;
					border-radius: 6px;
					display: flex;
					align-items: center;
					padding: 12px 16px;
					background: #111523;
					margin-bottom: 24px;

					i {
						font-size: 16px;
						color: #9E9E9E;
						flex-shrink: 0;
					}

					input {
						width: 100%;
						font-size: 16px;
						color: #9E9E9E;
						padding: 0 10px;
						background: #111523;
					}

					.getcode {
						width: 200px;
						height: 50px;
						font-size: 16px;
						color: #9E9E9E;
						border-left: 5px solid #171C2F;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}
				}

				.links {
					width: 100%;
					display: flex;
					margin-bottom: 8px;
					flex-direction: row-reverse;

					.link {
						font-size: 14px;
						color: #9E9E9E;
						cursor: pointer;
					}
				}

				.btns {
					margin-bottom: 72px;
					width: 100%;
					height: 44px;
					border-radius: 6px;
					display: flex;
					justify-content: center;
					font-size: 16px;
					font-weight: 500;
					align-items: center;
					padding: 14px 22px;
					cursor: pointer;
					background: #333647;
					color: #C0C2CC;
				}

				.wxbtn {
					margin-bottom: 72px;
					margin-top: -48px;
					width: 100%;
					height: 44px;
					border-radius: 6px;
					display: flex;
					justify-content: center;
					font-size: 16px;
					font-weight: 500;
					align-items: center;
					padding: 14px 22px;
					cursor: pointer;
					background: #252A40;
					color: #C0C2CC;

					img {
						width: 20px;
						height: 20px;
						object-fit: scale-down;
						margin: 0 5px;
					}
				}
			}

			.about {
				width: 496px;
				height: 498px;
				background: #171C2F;
				border: 2px solid #2E3241;
				z-index: 99;
				border-radius: 18px;
				margin-top: 10%;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 0 34px;

				.close {
					width: 100%;
					display: flex;
					flex-direction: row-reverse;
					margin-top: 20px;

					i {
						font-size: 20px;
						color: #9E9E9E;
						cursor: pointer;
					}
				}

				.us {
					width: 100%;
					font-size: 26px;
					font-weight: 600;
					color: #E9E9EF;
				}

				.title {
					width: 100%;
					display: flex;
					flex-direction: column;
					font-size: 14px;
					color: #9E9E9E;
					margin-top: 40px;

					span {
						color: #E9E9EF;
						font-weight: 500;
						margin-bottom: 10px;
					}
				}
			}
		}

		.filing {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;

			.text {
				width: 180px;
				height: 28px;
				border-radius: 4px 4px 0px 0px;
				background: rgba(12, 15, 28, 0.7);
				font-size: 12px;
				color: #9E9E9E;
				display: flex;
				align-items: center;
				justify-content: center;

				div {
					margin-left: 10px;
				}
			}
		}
	}
</style>