module.exports = {
	langage: {
		ch: "CH",
		en: "EN",
	},
	menu: {
		today: 'today',
		request: 'request',
		mostSaved: 'most saved',
		tasks: 'Tasks',
		timeline: 'timeline',
		getfeatured: 'getfeatured',
		jobimpach: 'job impach',
		infurencers: 'infurencers',
		followUS: 'follow US',
		login: 'Login',
		Logout: 'Log out',
	},
	index: {
		ssts: 'Search for your needs',
		more: 'More',
		featured: 'Featured',
		chrome: 'Chrome',
		ios: 'ios',
		android: 'Android',
		new: 'New',
		saved: 'Most saved',
	},
	details: {
		overview: 'Overview',
		reviews: 'Reviews',
		arosCons: 'Pros & Cons',
		QA: 'Q&A',
		alternatives: 'Alternatives',
		jobs: 'Jobs',
		Seealso: 'See also',
		fwwz: 'Visit website',
		save: 'Save',
		yjgzs: 'Most popular alternative: LearningStudioAI',
		gdgsj: 'view all 30 alternatives',
		recommendations: 'Recommendations',
		plpf: 'Would you recommend Notedly',
		bztr: 'Help other people by letting them know if this AI was useful',
		cppj: 'Leave a comment',
		post: 'Post',
		gnts: 'Featured requests',
		gdxf: "Are you looking for a specific feature that's not present in Notedly",
		txq: 'Request a feature',
		yhjx: 'Pros and Cons',
		pros: 'Pros',
		cons: 'Cons',
		cjwt: 'Q&A',
		xsgj: 'alternatives to Notedly for Note-taking',
		xggw: 'Most impacted jobs',
		cnxh: 'If you liked Notedly',
		yhrs: 'People also searched',
	},
	request: {
		gjts: 'AI Tool Requests',
		xqbz: 'Looking for a specific AI tool? Post a request and chances are someone will help you find it or, if it gathers enough votes, someone might create it.',
		dzd: 'Most Voted',
		zx: 'New',
		txq: 'New Requests',
		xqtj: 'New request',
		wtlx: 'This request is about',
		xzgj: 'Looking for an AI tool',
		zdtj: "Request a feature for an AI tool you're currently using",
		qt: 'Other',
		title: 'Title',
		xqlx: 'AI tool for writing academic papers',
		xxsm: 'Description',
		xqms: "Describe the AI tool(s) you're looking for. To get the best results, it's good to provide examples of specific features that interest you.",
		submit: 'Submit',
		hf: 'Add an answer',
		bz1: 'Can you help ',
		bz2: "find what they're looking for? Post your ideas here.",
	},
	other: {
		address: 'Address',
		phone: 'Phone',
		mail: 'Mail',
		zlogin: 'log in to eagle',
		email: 'Email address',
		password: 'Password',
		login: 'Login',
		code: 'Code',
		confirm: 'Confirm',
		emailLogin: 'Email login',
		wxLogin: 'WeChat login',
		wjmm: 'Forgot password',
		wzzye: 'Unregistered account, directly enter the registration page',
		hyyzm: 'verification code',
		dd: 'Wait for',
		s: 'S',
		zc: 'Register',
		csmm: 'Reset password',
		savedals: 'Saved Als',
		saved: 'Saved',
	}
}