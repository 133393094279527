<template>
	<div class="featured">
		<div class="title">
			{{$t('index.featured')}}
		</div>
		<Tool :list="list" :type="1"></Tool>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	export default {
		components: {
			Tool
		},
		data() {
			return {
				list: []
			}
		},
		mounted() {
			this.handfeatured()
		},
		methods: {
			handfeatured() {
				this.$request({
					url: '/tool/recommend',
					method: 'GET',
					params: {

					}
				}).then((res) => {
					if (res.code == 200) {
						this.list = res.rows
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.featured {
		width: 296px;
		padding-top: 100px;
		margin-top: -100px;

		.title {
			font-size: 26px;
			font-weight: 600;
			color: #C0C2CC;
			height: 36px;
			margin-bottom: 20px;
			position: relative;
			z-index: 9;
		}
	}
</style>