<template>
	<div class="plate">
		<div class="list" v-for="(item,index) in list" :key="index" @click.stop="handlink(item.id)">
			<div class="head">
				<img :src="item.logoImage" alt="" />
				<div :class="item.isCollected!=1?'like':'like likes'"
					@click.stop="handlike(item.isCollected,item.id,index)">
					<i
						:class="item.isCollected!=1?'iconfont icon-xihuan':'iconfont icon-like1'"></i>{{item.collectionCount}}
				</div>
			</div>
			<div class="name">
				{{item.toolName}}
			</div>
			<div class="desc">
				{{item.featureDescription}}
			</div>
			<div class="tags">
				<div class="tag">
					<img :src="item.functionImage" alt="" />{{item.functionDescription}}<i
						class="el-icon-arrow-right"></i>
				</div>
				<div class="tag" v-if="item.chargeType">
					{{item.chargeType}}
				</div>
			</div>
		</div>
		<div style="width: 296px;"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		props: {
			list: Array,
			type: Number,
			required: true
		},
		mounted() {},
		methods: {
			handlink(id) {
				this.$router.push({
					name: 'details',
					query: {
						id: id
					}
				})
			},
			handlike(isCollected, id, index) {
				if (!this.$store.state.login) {
					this.$store.state.loginshow = true
					return
				}
				if (isCollected == 0) {
					var url = "/subscriber/add"
				} else {
					var url = "/subscriber/cancel"
				}
				this.$request({
					url: url,
					method: 'GET',
					params: {
						id: id,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							customClass: "focus-message",
							type: 'success'
						});
						if (isCollected == 0) {
							this.list[index].isCollected = 1
							this.list[index].collectionCount = this.list[index].collectionCount + 1
						} else {
							this.list[index].isCollected = 0
							this.list[index].collectionCount = this.list[index].collectionCount - 1
						}
					}
				})
			}
		}
	}
</script>
<style lang="scss">
	.plate {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
	}


	.list {
		width: 296px;
		height: 208px;
		border-radius: 12px;
		background: #171C2F;
		backdrop-filter: blur(20px);
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		cursor: pointer;

		.head {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 248px;
			margin-top: 24px;

			img {
				width: 64px;
				height: 64px;
				border-radius: 12px;
			}

			.like {
				display: flex;
				align-items: center;
				font-size: 16px;
				color: #6B6D76;
				cursor: pointer;

				i {
					font-size: 16px;
					margin-right: 8px;
				}
			}

			.likes {
				color: #FF3636;
			}
		}

		.name {
			width: 248px;
			font-size: 20px;
			color: #E9E9EF;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-top: 14px;
		}

		.desc {
			width: 248px;
			font-size: 14px;
			color: #6B6D76;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.tags {
			width: 248px;
			display: flex;
			align-items: center;
			margin-top: 14px;

			.tag {
				height: 26px;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 6px 8px;
				color: #E9E9EF;
				background: #343848;
				margin-right: 8px;
				font-size: 14px;

				img {
					width: 15px;
					height: 15px;
					margin-right: 2px;
				}

				i {
					font-size: 14px;
					margin-left: 3px;
				}
			}
		}

	}

	.list:hover {
		background: #25293B;
	}
</style>