<template>
	<div class="main1">
		<div class="links">
			<i class="iconfont icon-home-alt"></i>/<div>Job impact</div>
		</div>
		<div class="boxMain">
			<div class="detail">
				<div class="table">
					<div class="tr">
						<div class="td">
							#
						</div>
						<div class="td task">
							Job title
						</div>
						<div class="td task">
							Al lmpact<i class="iconfont icon-rongqi1"></i>
						</div>
						<div class="td">
							Tasks
						</div>
						<div class="td">
							Als
						</div>
					</div>
					<div class="tr" @click="handlink">
						<div class="td">
							1
						</div>
						<div class="td task">
							Communications Manager
						</div>
						<div class="td task">
							<div class="progress">
								<div class="number" style="width: 98%;">
									98%
								</div>
							</div>
						</div>
						<div class="td">
							511
						</div>
						<div class="td">
							18,023
						</div>
					</div>
				</div>
			</div>
			<Featured></Featured>
		</div>
	</div>
</template>

<script>
	import Tool from '@/components/Tool'
	import Featured from '@/components/Featured'
	export default {
		components: {
			Tool,
			Featured
		},
		data() {
			return {}
		},
		mounted() {},
		methods: {
			handlink() {
				this.$router.push({
					name: 'data',
				})
			}
		}
	}
</script>

<style lang="scss">
	.main1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		// background: url("../../../static/images/background/background.png") no-repeat;
		// background-size: 100%;
		padding-top: 60px;

		.links {
			width: 1288px;
			height: 140px;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #6B6D76;

			i {
				font-size: 16px;
				color: #6B6D76;
				font-weight: bold;
				margin-right: 10px;
				cursor: pointer;
			}

			div {
				margin: 0 10px;
				cursor: pointer;
			}
		}

		.boxMain {
			width: 1288px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.detail {
				width: 928px;
				display: flex;
				flex-direction: column;


				.table {
					width: 100%;
					display: flex;
					flex-direction: column;

					.tr {
						width: 100%;
						height: 64px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-bottom: 1px solid #1A1F34;

						.td {
							width: 100px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 16px;
							color: #C0C2CC;

							img {
								width: 20px;
								height: 20px;
								object-fit: scale-down;
								margin-right: 8px;
							}

							i {
								font-size: 13px;
								margin-left: 8px;
							}

							.progress {
								width: 296px;
								height: 24px;
								border-radius: 4px;
								background: #171C2F;

								.number {
									height: 24px;
									border-radius: 4px;
									display: flex;
									justify-content: center;
									align-items: center;
									background: linear-gradient(270deg, #5F2C76 0%, #2C3E77 100%);
								}
							}
						}

						.task {
							width: 50%;
							justify-content: start;
						}
					}
				}
			}

			.featured {
				width: 296px;

				.title {
					font-size: 26px;
					font-weight: 600;
					color: #C0C2CC;
					height: 36px;
					margin-bottom: 20px;
				}
			}
		}




	}
</style>