export function typeOf (obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

export function retain (number, digit = 2) {
  let num = number + ''
  num = parseFloat(num.substring(0, num.indexOf('.') + (digit + 1)))
  return num
}

export function merge (firstArr, ...arr) {
  if (Object.is(typeOf(firstArr), 'array') && Object.is(typeOf(arr), 'array')) {
    firstArr = [...firstArr, ...arr] || firstArr.concat(...arr)
    return firstArr
  } else {
    return firstArr
  }
}

export function timeStamp (ns, string='') {
  let date = new Date(ns)
  let newDate = `${date.getFullYear()}${string}${date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}${string}${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`
  return newDate
}

export function timeStamps (ns) {
  let dates = new Date()
  let iDays = parseInt(Math.abs(dates.getTime() - ns) / 1000 / 60 / 60)
  return iDays
}
